@mixin button {
    background: none;
    padding: 10px 2rem;
    border-radius: 20px;
    color: $main-color;
    cursor: pointer;
    border: 1px solid $main-color;
    font-size: 15px;
    margin-top: 1rem;
}

@mixin hover {
    transform: scale(140%);
    transition: 1s ease-in-out;
    color: $secondary-color;
    background-color: $main-color;
}