@import '../../styles/colors';
@import '../../styles/buttons';

.main-section{
    background: linear-gradient(to right bottom, rgba(252,104,18,0.587),rgba(252,96,69,0.363)),url('../../assets/angela-bailey-jlo7Bf4tUoY-unsplash.jpg');
    height: 80vh;
    background-size: cover;
    overflow: hidden;
    width: 100%;

    .content{
        margin: 12rem;
        text-align: center;

        h1{
            font-size: 3rem;
            color: $main-color;
            text-shadow: 3px 3px 3px $main-color;
        }

        button{
            @include button;
        }
        button:hover{
            @include hover;
        }
    }
    
    .bottom{
        margin-left: 1rem;

        p{
            color: $main-color;
        }
    }
}