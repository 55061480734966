@import '../../styles/colors';

.second-section{
    background-color: $main-color;
    width: 100%;
    height: 100vh;
    padding: 2rem;

    .allcart{
        justify-content: center;
        display: flex;
        gap: 1rem;

        .cart{
            height: 100%;
            width: 100%;
            cursor: pointer;
        
            img{
                width: 80%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;
            }
        }
    }
}