@import '../../styles/colors';
@import '../../styles/mixins';

.main{
    width: 100%;
    padding-bottom: 10rem;
    background-color: $cream-color;
}

.center-text{
    @include flex-center;
    justify-content: center;

    h1{
        color: $gray-color;
        margin-top: 2rem;
        font-size: 4rem;
    }

}

.allcards{
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(4,200px);
    justify-content: space-around;
    gap: 3rem;
    text-align: center;

    .card{
        align-items: center;
    }
}