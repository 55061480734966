@import '../../styles/colors';
@import '../../styles/mixins';

.footer{
    width: 100%;
    height: 70vh;
    background-color: $main-color;
    @include flex-center;
    justify-content: space-around;

    p{
        padding-bottom: 2rem;
        color: $gray-color;

        span{
            display: block;
            margin-top: 2.2rem;
        }
    }
}