@import '../../styles/colors';
@import '../../styles/buttons';

.forms{
    background-color: $gray-color;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.form{
    color: $main-color;
    font-size: 20px;
    margin: 10rem 20rem;
    text-align: center;
    display: block;
    width: 50%;

    label{
        text-transform: uppercase;
    }

    input{
        width: 100%;
        padding: 1rem;
    }

    button{
        @include button;
    }
}