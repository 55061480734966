@import '../../styles/colors';
@import '../../styles/buttons';
@import '../../styles/mixins';

.women-section{
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/Women.jpg');
    background-size: cover;

    .header{
        @include flex-center;
        flex-direction: column;
        font-size: 2rem;
        color: $main-color;
    }

    .look{
        margin: 15rem;
        text-align: center;
        color: $main-color;
        font-size: 5rem;

        button{
            @include button;
        }

        button:hover{
            @include hover;
        }
    }
}
