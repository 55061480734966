@import '../../styles/colors';
.carousel-main{
    width: 100%;
    height: 100vh;
    background-color: $orange-section;
    position: relative;

    .arrows{
        cursor: pointer;
        color: $main-color;

        .left{
            position: absolute;
            font-size: 5rem;
            top: 50%;
            left: 1%;
        }

        .right{
            position: absolute;
            font-size: 5rem;
            top: 50%;
            right: 1%;
        }
    }
}

.carousel{
    opacity: 0;
    transition-duration: 1s;
}

.carousel.active{
    opacity: 1;
    transition-duration: 1s;
}

.data-item{
    color: $main-color;
    display: flex;
    flex-direction: column;
    align-items: center;

    .texts{
        margin-top: 1rem;

        p{
            margin: 2rem;
        }
    }

    img{
        width: 40%;
        margin-top: 1rem;
    }
}