@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/mixins';

.header{
    position: sticky;
    top: 0;

    nav{
        @include flex-center;
        justify-content: space-between;
        height: 4rem;
        background-color: $main-color;

        .ul-items{
            list-style: none;

            .li-items{
                a{
                    text-decoration: none;
                    color: $secondary-color;
                    margin: 0 1rem;
                    font-size: 12px;
                }
                .orange{
                    color: $orange-color;
                }
            }
        }

        .logo{
            text-transform: uppercase;
            margin-right: 2rem;
            font-family: $font-roboto;
        }

        .rigth{
            @include flex-center;

            .search{
                @include flex-center;
                margin-right: 5rem;

                .search-icon{
                    color: $gray-color;
                    font-size: 15px;
                }
                
                .sch-btn{
                    background: none;
                    border: none;
                    cursor: pointer;
                }
            }

            .rigth-buttons{
                @include flex-center;
                margin-right: 1rem;

                button{
                    margin: 0 1rem;
                    background: none;
                    border: none;
                    cursor: pointer;

                    a{
                        text-decoration: none;
                        color: $gray-color;
                    }
                }

                .basket{
                    color: $basket-color;
                }
            }
        }
    }
}